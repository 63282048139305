import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import './global.scss';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import * as React from 'react';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { BrowserRouter, Routes, Route, useNavigate, useLocation } from "react-router-dom";

import ConsentDetails from './components/ConsentDetails';
import Thankyou from './components/Thankyou';
import PrivacyPolicy from './components/PrivacyPolicy';
import Dashboard from './components/Dashboard';
import Dowanlaod from './components/downlaod';

// Create a client
const queryClient = new QueryClient();
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

function RedirectHandler() {
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    const { pathname } = location;
    
    const match = pathname.match(/^\/consent_bundle\/(\d+)\/download$/);
    if (match) {
      const newPath = `/${match[1]}/download`;
      navigate(newPath, { replace: true });
    }
  }, [location, navigate]);

  return null;
}

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#ec7c04',
      },
      secondary: {
        main: '#243474',
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <div className="App">
          <BrowserRouter>
            <RedirectHandler />
            <Routes>
              <Route exact path="/" element={<Dashboard />} />
              <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route exact path=":consentId/thankyou" element={<Thankyou />} />
              <Route exact path=":consentId" element={<ConsentDetails />} />
              <Route exact path=":consentId/download" element={<ConsentDetails />} />
              <Route exact path=":consentId/download/pdf" element={<Dowanlaod />} />
            </Routes>
          </BrowserRouter>
        </div>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
